import {
  Button,
  Burger,
  Drawer,
  Title,
  Anchor,
  Image,
  useMantineTheme,
  Text,
  Grid
} from '@mantine/core';
import React, { useEffect } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import { useLocation } from 'react-router-dom';
import { useUser } from '../Contexts/UserContext';
import LoginButton from './LoginButton';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const theme = useMantineTheme();
  // const { userProfile } = useUser();
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    console.log('isLoading', isLoading);
  }, [isLoading]);

  const [opened, setOpened] = React.useState(false);

  const location = useLocation();
  const { scroll_to } = location.state || { scroll_to: undefined };

  useEffect(() => {
    if (scroll_to) {
      scroller.scrollTo(scroll_to, {
        smooth: false,
        duration: 0
      });
    }
  });

  const title = opened ? 'Close navigation' : 'Open navigation';

  const getScrollLink = (text: any, destination: string) => {
    return (
      <Link to="/" style={anchorStyle}>
        {text}
      </Link>
    );
  };

  const anchorStyle = {
    color: theme.colorScheme === 'dark' ? '#fff' : '#000'
  };

  return (
    <header>
      <div className="content-desktop">
        <div className="logo-container">
          <Link to="/">
            <div style={{ display: 'flex' }}>
              <Image
                src="Trivium_logo_white_opt.svg"
                alt={'logo'}
                style={{
                  width: '25%',
                  filter:
                    theme.colorScheme === 'light'
                      ? 'invert(99%) sepia(74%) saturate(422%) hue-rotate(202deg) brightness(117%) contrast(100%)'
                      : undefined
                }}
              />
              <Text
                size={50}
                align="right"
                color={theme.colorScheme === 'dark' ? 'white' : 'black'}
                mx="lg"
                style={{ marginTop: 'auto', lineHeight: '1.0' }}
              >
                <span className="bunkenSemiBold" style={{ lineHeight: 'auto' }}>
                  Trivium
                </span>
                {/* <span className="bunkenLight" style={{lineHeight: 'auto'}}>API</span> */}
                <Text
                  className="bunkenLight"
                  component="span"
                  variant="gradient"
                  gradient={{ from: 'blue', to: 'cyan' }}
                  style={{ lineHeight: 'auto' }}
                  inherit
                >
                  API
                </Text>
              </Text>
            </div>
          </Link>
        </div>
        <div className="navbar">
          {isAuthenticated ? (
            <Grid>
              <Grid.Col sm={12} md={6}>
                <div className="navbar-email scroll_buttons">{user?.email}</div>
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <div className="navbar-links">
                  <div className="navbar-item scroll_buttons">
                    <Link to="/dashboard" style={anchorStyle}>
                      Dashboard
                    </Link>
                  </div>
                  <div className="navbar-item scroll_buttons">
                    <Link to="/logout" style={anchorStyle}>
                      Logout
                    </Link>
                  </div>
                </div>
              </Grid.Col>
            </Grid>
          ) : (
            <div className="navbar-item scroll_buttons">
              {/* <Link to='/login' style={anchorStyle}>Login</Link> */}
              <LoginButton />
            </div>
          )}
          {/* <div style={{margin: '0 25px'}}> */}
          <ThemeToggle />
          {/* </div> */}
        </div>
      </div>

      <div className="content-mobile">
        <div className="logo-container">
          {getScrollLink(
            <Image
              src="Trivium-black.svg"
              alt={'logo'}
              style={{
                width: '100%',
                filter:
                  theme.colorScheme === 'dark'
                    ? 'invert(99%) sepia(74%) saturate(422%) hue-rotate(202deg) brightness(117%) contrast(100%)'
                    : undefined
              }}
            />,
            'about'
          )}
        </div>
        <div className="navbar">
          <ThemeToggle />
          <div className="burger-button">
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              title={title}
              size="lg"
            />
          </div>
        </div>
      </div>
      <Drawer
        transition="rotate-right"
        transitionDuration={250}
        transitionTimingFunction="ease"
        overlayOpacity={0.55}
        position="right"
        closeButtonLabel="Close drawer"
        title="Menu"
        padding="xl"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className="menu">
          <div className="menu-items">
            <div className="menu-item">
              <Link to="/dashboard" style={anchorStyle}>
                <Title order={1}>Dashboard</Title>
              </Link>
            </div>
            <div className="menu-item">
              <Link to="/profile" style={anchorStyle}>
                <Title order={2}>Profile</Title>
              </Link>
            </div>
            <div className="menu-item">
              <Link to="/login" style={anchorStyle}>
                <Title order={3}>Login</Title>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
    </header>
  );
};

export default Header;
