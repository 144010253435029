import {
  ButtonStylesParams,
  MantineProvider,
  MantineTheme,
  MantineThemeOverride
} from '@mantine/core';
import { TypographyStylesProvider } from '@mantine/core';
import { ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useState } from 'react';
import { useColorScheme } from '@mantine/hooks';
import { useCookies } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import Main from './Pages/main';
import Tools from './Pages/tools';
import Home from './Pages/home';
import Login from './Pages/login';
import { UserProvider } from './Contexts/UserContext';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Register from './Pages/register';
import Profile from './Pages/profile';
import Logout from './Pages/logout';
import Dashboard from './Pages/dashboard';
import NotificationsPage from './Pages/notifications';
import BillingPage from './Pages/billing';
import ApiKeyPage from './Pages/apikeys';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

const lightTheme: MantineThemeOverride = {
  colorScheme: 'light',
  globalStyles: (theme) => ({
    body: {
      ...theme.fn.fontStyles(),
      backgroundColor: theme.colors.blue[0]
    }
  })
};

const darkTheme: MantineThemeOverride = {
  colorScheme: 'dark',
  components: {
    // Button: {
    //   // Subscribe to theme and component params
    //   styles: (theme, params: ButtonStylesParams) => ({
    //     root: {
    //       height: 42,
    //       padding: '0 30px',
    //       backgroundColor:
    //         params.variant === 'filled'
    //           ? theme.colors[params.color || theme.primaryColor][9]
    //           : undefined,
    //     },
    //   }),
    // },

    Anchor: {
      // Use raw styles object if you do not need theme dependency
      styles: {
        root: { color: '#fff' }
      }
    }
  }
};

const queryClient = new QueryClient();

function App() {
  const [cookies, setCookie] = useCookies(['trivium']);
  const preferredColorScheme = useColorScheme();
  const prefScheme = cookies.trivium || preferredColorScheme || 'dark';

  const [theme, setTheme] = useState<MantineThemeOverride>(
    prefScheme === 'dark' ? darkTheme : lightTheme
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    const nextScheme =
      value || theme.colorScheme === 'dark' // Is the current theme dark?
        ? 'light' // If yes, next scheme is "light"
        : 'dark'; // If no, next scheme is "dark"

    const nextTheme = nextScheme === 'dark' ? darkTheme : lightTheme;

    setTheme(nextTheme);
    setCookie('trivium', nextScheme, { maxAge: 60 * 60 * 24 * 30 });
  };

  return (
    <ColorSchemeProvider
      colorScheme={theme.colorScheme as ColorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <TypographyStylesProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <Routes>
                <Route index element={<Home />} />

                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="profile" element={<Profile />} />
                <Route path="register" element={<Register />} />
                <Route path="dashboard" element={<Dashboard />}>
                  <Route index element={<NotificationsPage />} />
                  <Route path="notifications" element={<NotificationsPage />} />
                  <Route path="billing" element={<BillingPage />} />

                  <Route path="apikeys" element={<ApiKeyPage />} />
                </Route>
              </Routes>
            </UserProvider>
          </QueryClientProvider>
        </TypographyStylesProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
