import { Container, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import { useUser } from '../Contexts/UserContext';
import { user_logout } from '../Utils/Backend/UserHelper';
import { useAuth0 } from '@auth0/auth0-react';

export default function Logout() {
  // const { userProfile, changeUserProfile } = useUser();

  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(()=>{
    handleLogout();
  });

  const handleLogout = async () => {
    logout({ returnTo: window.location.origin });
    // if (userProfile?.token){
    //   try {
    //     const result = await user_logout(userProfile?.token);
    //     console.log(result);
    //   } catch(err) {
    //     console.error(err);
    //   }
    // }
    // changeUserProfile(undefined);
    // navigate('/');
  };

  return (
    <>
      <Header />
      <Container style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Loader height="20vh" />
      </Container>
      {/* <Footer /> */}
    </>
  );
}