import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons';
  
const mockdata = [
  {
    title: 'Extreme Performance',
    description:
        'High performance servers and on-premesis load-balancers located around the world ensure that the data your app needs is always available.',
    icon: IconGauge,
  },
  {
    title: 'Some Feature',
    description:
        'Curabitur pharetra lectus ac nunc tristique consequat. Aliquam eget ultricies massa. Mauris ut sapien eu erat interdum porta. Mauris porttitor turpis vitae erat iaculis vestibulum.',
    icon: IconUser,
  },
  {
    title: 'Another Feature',
    description:
        'Pellentesque dapibus, mauris eu tincidunt dictum, nibh dolor congue ligula, eget dignissim risus nulla nec orci.',
    icon: IconCookie,
  },
];
  
const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  inner: {
    position: 'relative',
    paddingTop: 200,
    paddingBottom: 120,
  },
  
  description: {
    maxWidth: 600,
    margin: 'auto',
  
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  
  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },
  
  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));
  
export function FeaturesIntroSection() {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
      <feature.icon size={50} stroke={2} color={theme.fn.primaryColor()} />
      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));
  return (
    <Container  size={900} className={classes.inner}>
  
      <Title order={2} className={classes.title} align="center" mt="sm">
        Data for{' '}
        <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
            dApps
        </Text>
      </Title>
  
      <Text color="dimmed" className={classes.description} align="center" mt="md">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus, est vel placerat accumsan, nibh leo tempus lacus, vitae sollicitudin nulla risus at massa.
      </Text>
  
      <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        {features}
      </SimpleGrid>
    </Container>
  );
}