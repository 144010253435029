import { Image, Text, Container, ThemeIcon, Title, SimpleGrid, createStyles } from '@mantine/core';
import chains, { Chain } from '../Data/chains';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: "1vh 10vw",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : undefined,
    border: theme.colorScheme === 'dark' ? '1px solid #2C2E33' : undefined,
    borderRadius: '8px',
    boxShadow: theme.colorScheme === 'dark' ? "0 1px 3px rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0px 20px 25px -5px,rgba(0, 0, 0, 0.04) 0px 10px 10px -5px" : undefined,
    marginBottom: '20vh',
  },

  item: {
    display: 'flex',
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
}));

export function ChainList() {
  const { classes } = useStyles();
  const data = chains;
  const items = data.map((chain) => (
    <div className={classes.item} key={chain.image}>
        <Image src={require(`../Images/${chain.image}`)} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
    </div>
  ));

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>
      Supported Chains
      </Title>

      <SimpleGrid
        cols={3}
        spacing={50}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 60, marginBottom: 40 }}
      >
        {items}
      </SimpleGrid>
    </Container>
  );
}

// export function ChainList() {
//   const { classes } = useStyles();
//   const data = chains;
//   const items = data.map((chain) => (
//     <div className={classes.item} key={chain.image}>
//       <ThemeIcon variant="light" className={classes.itemIcon} size={60} radius="md">
//         <Image src={require(`../Images/${chain.image}`)} />
//       </ThemeIcon>

//       <div>
//         <Text weight={700} size="lg" className={classes.itemTitle}>
//           {chain.name}
//         </Text>
//         {/* <Text color="dimmed">{chain.description}</Text> */}
//       </div>
//     </div>
//   ));

//   return (
//     <Container size={700} className={classes.wrapper}>
//       <Text className={classes.supTitle}>Supported Chains</Text>

//       <Title className={classes.title} order={2}>
//         PharmLand is <span className={classes.highlight}>not</span> just for pharmacists
//       </Title>

//       <Container size={660} p={0}>
//         <Text color="dimmed" className={classes.description}>
//           "aaa"
//         </Text>
//       </Container>

//       <SimpleGrid
//         cols={2}
//         spacing={50}
//         breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
//         style={{ marginTop: 30 }}
//       >
//         {items}
//       </SimpleGrid>
//     </Container>
//   );
// }