import {
  createContext,
  useState,
  useContext,
  ReactElement,
  ReactNode,
  useEffect
} from 'react';
import { UserLoginDto } from '@astroapi/types';
import { useAuth0 } from '@auth0/auth0-react';

export interface UserContextState {
  accessToken?: string;
}

interface Props {
  children: ReactNode;
}

// created context with no default values
const UserContext = createContext<UserContextState>({});

export const UserProvider = ({ children }: Props): ReactElement => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    console.log('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
      getAccessToken();
      console.log(user);
    } else {
      setAccessToken(undefined);
    }
  }, [isAuthenticated]);

  const getAccessToken = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_BACKEND_URL,
      scope: 'read:current_user openid profile email'
    });
    setAccessToken(accessToken);
  };

  const values = {
    accessToken
  };

  // add values to provider to reach them out from another component
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// created custom hook
export const useUser = (): UserContextState => useContext(UserContext);
