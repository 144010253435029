import {
  ApiKey,
  CreateCheckoutDto,
  CreateUserDto,
  User,
  UserLoginDto
} from '@astroapi/types';
import { get_wrapper, post_wrapper } from './BackendWrapper';

interface response_wrapper {
  data: any;
  message: string;
}

export const checkout_get = async (
  token: string,
  price_id: string
): Promise<string> => {
  const data: CreateCheckoutDto = { price_id };
  const response: response_wrapper = await post_wrapper({
    path: '/stripe/create_checkout',
    token,
    data
  });

  return response.data;
};

export const portal_session_get = async (token: string): Promise<string> => {
  const response: response_wrapper = await post_wrapper({
    path: '/stripe/create_portal_session',
    token
  });

  return response.data;
};
