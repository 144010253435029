import { Container, Title, SimpleGrid, createStyles } from '@mantine/core';
import { DashNotif } from '../Components/Notification';
import { PriceCards } from '../Components/PriceList';
import { useUser } from '../Contexts/UserContext';
import { portal_session_get } from '../Utils/Backend/StripeHelper';
const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '2vh 5vw'
  },

  item: {
    display: 'flex'
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
      .color,
    letterSpacing: 0.5
  },

  title: {
    lineHeight: 1,
    textAlign: 'center'
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs
  },

  highlight: {
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor
    }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit'
  }
}));

export default function BillingPage() {
  const { classes } = useStyles();
  const { accessToken } = useUser();

  const handlePortal = async () => {
    const url = await portal_session_get(accessToken as string);
    // window.location.replace(url);
    window.open(url, '_blank', 'noopener');
  };

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>Billing</Title>

      <PriceCards />

      <a onClick={() => handlePortal()}>Customer Portal</a>
    </Container>
  );
}
