import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  Table,
  Avatar,
  Checkbox
} from '@mantine/core';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons';
import { checkout_get } from '../Utils/Backend/StripeHelper';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '../Contexts/UserContext';
import { Plan, PlanID, Subscription } from '@astroapi/types';
import { ActiveSubscriptionsQuery } from '../Utils/Backend/SubscriptionHelper';
import { nFormatter } from '../Utils/tools';
import { json } from 'stream/consumers';

export const plans: Plan[] = [
  {
    planId: 'free',
    req_day: 250_000,
    req_sec: 10,
    curr_conn: 5,
    price_id: ''
  },
  {
    planId: 'plus',
    req_day: 1_000_000,
    req_sec: 25,
    curr_conn: 10,
    price_id: 'price_1MULmcHd6v9qQU5H5ruP2VnZ'
  },
  {
    planId: 'premium',
    req_day: 5_000_000,
    req_sec: 50,
    curr_conn: 25,
    price_id: 'price_1MULn8Hd6v9qQU5HipDP4GRm'
  }
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24
    }
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm
    }
  }
}));

interface PriceCardData {
  title: string;
  desc: string;
  req_day: number;
  req_sec: number;
  curr_conn: number;
  price_id: string;
  plan_id: PlanID;
  price?: number;
}

interface PriceCardProps extends PriceCardData {
  subscribed: boolean;
}

const prices: PriceCardData[] = plans.map((plan: Plan) => {
  switch (plan.planId) {
  case 'free': {
    return {
      title: 'Free',
      plan_id: 'free',
      desc: 'Basic access for developers and small apps.',
      ...plan
    };
  }
  case 'plus': {
    return {
      title: 'Plus',
      plan_id: 'plus',
      desc: 'For growing apps or larger projects.',
      ...plan
    };
  }
  case 'premium': {
    return {
      title: 'Premium',
      plan_id: 'premium',
      desc: 'For large apps.',
      ...plan
    };
  }
  }
});

export function PriceCards() {
  const { classes, theme } = useStyles();
  const { accessToken } = useUser();

  const activeSubscriptions = accessToken
    ? ActiveSubscriptionsQuery(accessToken)
    : undefined;
  console.log('activeSubscriptions', activeSubscriptions?.data);

  let subscribedPlan: PlanID = 'free';
  if (activeSubscriptions?.data?.length) {
    activeSubscriptions.data.every((sub: Subscription) => {
      if (sub.planId === 'premium') {
        subscribedPlan = sub.planId;
        // User is subscribed to the highest plan, no need to continue
        return false;
      }

      // user is subscribed to a plan, lets keep checking though
      if (sub.planId === 'plus') {
        subscribedPlan = sub.planId;
      }

      // keep checking
      return true;
    });
  }

  const handleCheckout = async (price_id: string) => {
    const url = await checkout_get(accessToken as string, price_id);
    window.location.replace(url);
  };

  function PriceCard({
    title,
    desc,
    req_day,
    req_sec,
    curr_conn,
    price,
    subscribed,
    price_id
  }: PriceCardProps) {
    return (
      <Card
        shadow="md"
        radius="md"
        className={classes.card}
        p="xl"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <IconGauge size={50} stroke={2} color={theme.fn.primaryColor()} />
        <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
          {title}
        </Text>
        <Text size="sm" color="dimmed" mt="sm" style={{ marginBottom: 'auto' }}>
          {desc}
        </Text>

        <Table verticalSpacing="sm">
          <tbody>
            <tr>
              <td>Requests/day</td>
              <td>{nFormatter(req_day, 0)}</td>
            </tr>
            <tr>
              <td>Requests/second</td>
              <td>{req_sec}</td>
            </tr>
            <tr>
              <td>Concurrent Connections</td>
              <td>{curr_conn}</td>
            </tr>
          </tbody>
        </Table>
        {!!price && !subscribed && (
          <Text color={theme.fn.primaryColor()} mt="sm" align="right">
            ${price} / month
          </Text>
        )}
        {!!subscribed && (
          <Text color={theme.fn.primaryColor()} mt="sm" align="right">
            Current Plan
          </Text>
        )}
        <div
          // href="#"
          onClick={() => handleCheckout(price_id)}
        >
          Checkout?
        </div>
      </Card>
    );
  }
  const priceCards = prices.map((price) => {
    return (
      <PriceCard
        {...price}
        key={price.title}
        subscribed={subscribedPlan === price.plan_id}
      />
    );
  });
  return (
    <>
      <Title order={2} className={classes.title} align="center" mt="sm">
        Many plans to fit your needs
      </Title>

      {/* <Text color="dimmed" className={classes.description} align="center" mt="md">
        
      </Text> */}

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
      >
        {/* <Card shadow="md" radius="md" className={classes.card} p="xl">
          <IconGauge size={50} stroke={2} color={theme.fn.primaryColor()} />
          <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
            Free
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            Basic access for developers and small apps.
          </Text>

          <Table verticalSpacing="sm">
            <tbody>
              <tr>
                <td>Requests/day</td>
                <td>250k</td>
              </tr>
              <tr>
                <td>Requests/second</td>
                <td>10</td>
              </tr>
            </tbody>
          </Table>
        </Card>
        <Card shadow="md" radius="md" className={classes.card} p="xl">
          <IconGauge size={50} stroke={2} color={theme.fn.primaryColor()} />
          <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
            Plus
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            For growing apps or larger projects.
          </Text>

          <Table verticalSpacing="sm">
            <tbody>
              <tr>
                <td>Requests/day</td>
                <td>1m</td>
              </tr>
              <tr>
                <td>Requests/second</td>
                <td>25</td>
              </tr>
            </tbody>
          </Table>

          <Text color={theme.fn.primaryColor()} mt="sm" align='right'>
            $50 / month
          </Text>
        </Card> */}
        {priceCards}
      </SimpleGrid>
      <p>
        Active Subscriptions?:
        {JSON.stringify(activeSubscriptions?.data, undefined, 2)}
      </p>
    </>
  );
}
