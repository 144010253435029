import { useMantineTheme, Container, Grid, Text, Button, Group, Avatar, UnstyledButton, Anchor, Code, Image } from '@mantine/core';

const Footer = () => {
  const theme = useMantineTheme();
    
  return (
    <footer style={{ backgroundColor: theme.colors.gray[9]}}>

      <Container>
        <Grid justify={'center'}>

          <Grid.Col xs={12} sm={10} md={8} lg={8} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div style={{width: '30%'}}>
              <Image src="Trivium_logo_white_opt.svg" alt={'Trivium'}/>
            </div>
                        
            <Text size={70} align='center' color="white"mb="10px"><span className="bunkenSemiBold">Trivium</span><span className="bunkenLight">Node</span></Text>
            <Anchor href="mailto:info@trivium.network" target="_blank" rel="noreferrer noopener" style={{ color: 'white' }}><Text size="xl" align='center' color="white" weight={500} mb="10px">info@trivium.network</Text></Anchor>

          </Grid.Col>
        </Grid>
      </Container>
    </footer>
  )
};

export default Footer;

const redirectToLink = (link: string): void => {
  window.open(link, '_blank');
};