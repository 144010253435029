import Header from '../Components/Header';
// import { RegisterForm } from '../Components/RegisterForm';

export default function Register() {
  return (
    <>
      <Header />
      {/* <RegisterForm /> */}
      {/* <Footer /> */}
    </>
  );
}
