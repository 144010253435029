import { DashboardNav } from '../Components/DashboardNav';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from './home';
import NotificationsPage from './notifications';

export default function Dashboard() {

  return (
    <div style={{display: 'flex'}}>
      <DashboardNav />

      <Outlet />
      {/* <Routes> */}
      
      {/* <Route index element={ <NotificationsPage /> } /> */}
      {/* <Route path="/" element={ <NotificationsPage /> } /> */}
      {/* <Route path="/notifications" element={ <NotificationsPage /> } /> */}
          
      {/* </Routes> */}
    </div>
  );
}