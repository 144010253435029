import { ApiKey, CreateUserDto, User, UserLoginDto } from '@astroapi/types';

interface response_wrapper {
  data: any;
  message: string;
}

const post_wrapper = async ({path, data, token}: { path: string, data?: any, token?: string }) => {
  const url = new URL(path, process.env.REACT_APP_BACKEND_URL as string);

  let authHeader = {};
  if (token){
    authHeader = {
      'Authorization': 'Bearer '+ token
    };
  }

  const response = await fetch(url, {
    method: 'POST',
    headers:  {
      'Content-Type': 'application/json',
      ...authHeader
    },
    body: data ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

const get_wrapper = async ({path, token}: { path: string, token?: string }) => {
  const url = new URL(path, process.env.REACT_APP_BACKEND_URL as string);

  let authHeader = {};
  if (token){
    authHeader = {
      'Authorization': 'Bearer '+ token
    };
  }

  const response = await fetch(url, {
    method: 'GET',
    headers:  {
      ...authHeader
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

export const apikeys_get = async (token: string): Promise<ApiKey[]> => {
  const response: response_wrapper =  await get_wrapper({path:'/api_keys/all', token});
  const data: ApiKey[] = response.data.map((item: ApiKey)=>dateFixer(item));
  return data;
};

export const apikeys_new = async (token: string): Promise<ApiKey> => {
  const response: response_wrapper =  await post_wrapper({path:'/api_keys/new', token});
  return response.data;
};

const dateFixer = (apiKey: ApiKey) => {
  if (apiKey.createdAt) apiKey.createdAt = new Date(apiKey.createdAt);
  if (apiKey.deactivatedAt) apiKey.deactivatedAt = new Date(apiKey.deactivatedAt);
  return apiKey;
};
