import { ChainList } from '../Components/ChainList';
import { FeaturesIntroSection } from '../Components/FeaturesIntroSection';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import IntroSection from '../Components/IntroSection';
// import { LoginForm } from "../Components/LoginForm";
import Content from '../Views/MainContent';

export default function Login() {
  return (
    <>
      <Header />
      {/* <LoginForm /> */}
      {/* <Footer /> */}
    </>
  );
}
