import { ChainList } from "../Components/ChainList";
import { FeaturesIntroSection } from "../Components/FeaturesIntroSection";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import IntroSection from "../Components/IntroSection";
import Content from "../Views/MainContent";

export default function Home() {

  return (
    <>
      <Header />
      <FeaturesIntroSection />
      <ChainList />
      <Footer />
    </>
  )
}