export interface Chain {
    name: string;
    cosmosId: string;
    pingMirror: string;
    valAddress: string;
    restApi: string;
    chainId: string;
    image: string;
}

const chains: Chain[] = [
    {
        name: 'Secret Network',
        cosmosId: 'secretnetwork',
        pingMirror: 'https://explorer.trivium.network/secret',
        valAddress: 'secretvaloper1ahawe276d250zpxt0xgpfg63ymmu63a0svuvgw',
        restApi: 'https://secret-4.api.trivium.network:1317',
        chainId: 'secret-4',
        image: 'secret.png',
    },
    {
        name: 'Sentinel',
        cosmosId: 'sentinel',
        pingMirror: 'https://explorer.trivium.network/sentinel',
        valAddress: 'sentvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql82nz4kg',
        restApi: 'https://api-sentinel-ia.cosmosia.notional.ventures',
        chainId: 'sentinelhub-2',
        image: 'sentinel.png',
    },
    {
        name: 'Akash',
        cosmosId: 'akash',
        pingMirror: 'https://explorer.trivium.network/akash',
        valAddress: 'akashvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8f5gq57',
        //restApi: 'https://akash-api.lavenderfive.com',
        restApi: 'https://rest.cosmos.directory/akash',
        chainId: 'akashnet-2',
        image: 'akash.png',
    },
    {
        name: 'Jackal',
        cosmosId: 'jackal',
        pingMirror: 'https://explorer.trivium.network/jackal',
        valAddress: 'jklvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8zq9eug',
        restApi: 'https://api.jackalprotocol.com',
        chainId: 'jackal-1',
        image: 'jackal.png',
    },
    {
        name: 'Band Protocol',
        cosmosId: 'bans',
        pingMirror: 'https://explorer.trivium.network/band',
        valAddress: 'bandvaloper1z5nwqcxltx89zt07jmw9jtx2jlerawsauptl6j',
        restApi: 'https://laozi1.bandchain.org/api',
        chainId: 'laozi-mainnet',
        image: 'band.png',
    },
    {
        name: 'Kava',
        cosmosId: 'kava',
        pingMirror: 'https://explorer.trivium.network/kava',
        valAddress: 'kavavaloper1lvlvv4mllg768ay479h79e2wpv9wjm24gpukcs',
        restApi: 'https://rest.cosmos.directory/kava',
        chainId: 'kava_2222-10',
        image: 'kava.png',
    },
    {
        name: 'IRISnet',
        cosmosId: 'irisnet',
        pingMirror: 'https://explorer.trivium.network/iris',
        valAddress: 'iva1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8w7plzc',
        restApi: 'https://rest.cosmos.directory/irisnet',
        chainId: 'irishub-1',
        image: 'irisnet.png',
    },
    {
        name: 'Rizon',
        cosmosId: 'rizon',
        pingMirror: 'https://explorer.trivium.network/rizon',
        valAddress: 'rizonvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql87543nx',
        restApi: 'https://rest.cosmos.directory/rizon',
        chainId: 'titan-1',
        image: 'rizon.png',
    },
    {
        name: 'IDEP',
        cosmosId: 'idep',
        pingMirror: 'https://explorer.trivium.network/idep',
        valAddress: 'idepvaloper1qwt5w9mm2p5nayxhquetgvq5dm2aadhnt546xr',
        restApi: 'https://idep-api.lavenderfive.com',
        chainId: 'Antora',
        image: 'idep.png',
    },
]

export default chains;