import { Subscription } from '@astroapi/types';
import { get_wrapper } from './BackendWrapper';
import { useQuery } from '@tanstack/react-query';

interface response_wrapper {
  data: any;
  message: string;
}

export const subscriptions_get = async (
  token: string
): Promise<Subscription[]> => {
  const response: response_wrapper = await get_wrapper({
    path: '/subscriptions',
    token
  });
  const data: Subscription[] = response.data.map((item: Subscription) =>
    dateFixer(item)
  );
  return data;
};

export const subscriptions_get_active = async (
  token: string
): Promise<Subscription[]> => {
  const response: response_wrapper = await get_wrapper({
    path: '/subscriptions/active',
    token
  });
  const data: Subscription[] = response.data.map((item: Subscription) =>
    dateFixer(item)
  );
  return data;
};

const dateFixer = (subscription: Subscription) => {
  if (subscription.createdAt)
    subscription.createdAt = new Date(subscription.createdAt);
  if (subscription.updatedAt)
    subscription.updatedAt = new Date(subscription.updatedAt);

  subscription.startDate = new Date(subscription.startDate);
  subscription.expireDate = new Date(subscription.expireDate);
  return subscription;
};

export const ActiveSubscriptionsQuery = (token?: string) => {
  return useQuery({
    queryKey: ['subscriptions', token],
    queryFn: () => {
      return subscriptions_get_active(token as string);
    }
  });
};
