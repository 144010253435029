import { Container, Loader } from '@mantine/core';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import { useUser } from '../Contexts/UserContext';
import { user_logout } from '../Utils/Backend/UserHelper';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  children: ReactNode;
  className?: string;
}

export default function LogoutButton({children, className}: Props) {
  const { logout } = useAuth0();

  return (
    <div className={className} onClick={()=>logout({ returnTo: window.location.origin })}>
      {children}
    </div>
  );
}