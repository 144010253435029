export const post_wrapper = async ({
  path,
  data,
  token
}: {
  path: string;
  data?: any;
  token?: string;
}) => {
  const url = new URL(path, process.env.REACT_APP_BACKEND_URL as string);

  let authHeader = {};
  if (token) {
    authHeader = {
      Authorization: 'Bearer ' + token
    };
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader
    },
    body: data ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

export const get_wrapper = async ({
  path,
  token
}: {
  path: string;
  token?: string;
}) => {
  const url = new URL(path, process.env.REACT_APP_BACKEND_URL as string);

  let authHeader = {};
  if (token) {
    authHeader = {
      Authorization: 'Bearer ' + token
    };
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...authHeader
    }
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};
