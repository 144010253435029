import { Notification } from '@astroapi/types';
import {
  createStyles,
  Text,
  Avatar,
  Group,
  TypographyStylesProvider,
  Paper
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  comment: {
    padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : undefined
  },

  body: {
    paddingLeft: 54,
    paddingTop: theme.spacing.sm,
    fontSize: theme.fontSizes.sm
  },

  content: {
    '& > p:last-child': {
      marginBottom: 0
    }
  }
}));

type NotificationType = 'billing' | 'info' | 'alert';

export function DashNotif({
  createdAt,
  notification_type,
  title,
  text,
  metadata
}: Notification) {
  const { classes } = useStyles();
  return (
    <Paper withBorder radius="md" className={classes.comment}>
      <Group>
        <div>
          <Text size="sm">{title}</Text>
          <Text size="xs" color="dimmed">
            {createdAt?.toString()}
          </Text>
        </div>
      </Group>
      <TypographyStylesProvider className={classes.body}>
        <div className={classes.content}>{text}</div>
      </TypographyStylesProvider>
    </Paper>
  );
}
