import {
  Container,
  Title,
  SimpleGrid,
  createStyles,
  Text,
  Button,
  Loader,
  Group,
  Anchor,
  PasswordInput,
  Badge,
  ScrollArea,
  Table
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { apikeys_get, apikeys_new } from '../Utils/Backend/ApiKeyHelper';
import { ApiKey } from '@astroapi/types';
import { useUser } from '../Contexts/UserContext';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { PLACEHOLDER } from '../Utils/constants';
import { diffDays } from '../Utils/dates';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '1vh 2vw',
    minWidth: '50%'
  },

  title: {
    lineHeight: 1,
    textAlign: 'center'
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs
  },

  key: {
    textAlign: 'center',
    fontSize: '120%',
    fontFamily: 'Courier'
  }
}));

export default function ApiKeyPage() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { accessToken } = useUser();
  const { classes } = useStyles();

  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingKeys, setLoadingKeys] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);

  useEffect(() => {
    refreshKeys();
  }, [isAuthenticated]);

  const refreshKeys = async () => {
    try {
      setLoadingKeys(true);
      if (!accessToken) throw 'no access token. todo handle this 2'; //todo handle this
      const keys = await apikeys_get(accessToken);
      console.log('Refresh Result', keys);
      keys.sort((a, b) => {
        if (a.active && b.active) return 0;
        if (a.active) return -1;
        if (b.active) return 1;

        if (a.deactivatedAt && b.deactivatedAt) {
          if (a.deactivatedAt > b.deactivatedAt) return -1;
          else return 1;
        }
        if (a.deactivatedAt && !b.deactivatedAt) return -1;
        else return 1;
      });
      setApiKeys(keys);
    } catch (err: any) {
      toast.error(err.toString());
    } finally {
      setLoadingKeys(false);
    }
  };

  const handleNewKey = async () => {
    try {
      if (!accessToken) throw 'no access token. todo handle this 1';
      setLoadingNew(true);

      // Have backend create new key. Backend will deactivate old keys.
      const result = await apikeys_new(accessToken);

      // Create a new array to set to state
      const newAry = [...apiKeys];

      // Set all the old keys to inactive locally. No need to query again.
      newAry.forEach((item) => {
        if (item.active) {
          item.active = false;
          item.deactivatedAt = new Date();
        }
      });

      // Add the new key to the beginning of the array
      newAry.unshift(result);

      // Set new array to state
      setApiKeys(newAry);
    } catch (err: any) {
      toast.error(err.toString());
    } finally {
      setLoadingNew(false);
    }
  };

  const renderRow = (item: ApiKey) => {
    const daysSinceDeactivate = item.deactivatedAt
      ? diffDays(item.deactivatedAt, new Date())
      : 0;
    const daysText = daysSinceDeactivate
      ? `${daysSinceDeactivate} ${daysSinceDeactivate > 1 ? 'days' : 'day'} ago`
      : 'Today';
    return (
      <tr key={item.key}>
        <td style={{ fontFamily: 'Courier' }}>
          {revealed ? item.key : PLACEHOLDER}
        </td>

        <td>
          {item.active ? (
            <Badge fullWidth>Active</Badge>
          ) : (
            <Badge color="gray" fullWidth>
              Disabled
            </Badge>
          )}
        </td>

        <td style={{ textAlign: 'right' }}>
          {item.active ? '' : item.deactivatedAt ? daysText : 'Unknown'}
        </td>
      </tr>
    );
  };

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>API Key Management</Title>

      <Text className={classes.description} mt="10vh">
        Your API Key
      </Text>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '3vh'
        }}
      >
        <Text className={classes.key}>
          {revealed ? apiKeys[0].key : PLACEHOLDER}
        </Text>
        {revealed ? (
          <AiOutlineEyeInvisible
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={() => setRevealed(false)}
          />
        ) : (
          <AiOutlineEye
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={() => setRevealed(true)}
          />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <Button onClick={() => handleNewKey()} disabled={loadingNew}>
          {loadingNew && (
            <>
              <Loader />
              &nbsp;
            </>
          )}
          New Key
        </Button>
      </div>

      <Text className={classes.description} mb="15vh">
        Creating a new key will invalidate your current key.
        <br />
        Any applications using the current key will need to be updated.
      </Text>

      <h2 className={classes.title}>Key History</h2>
      <ScrollArea>
        <Table sx={{ minWidth: '100%' }} verticalSpacing="sm">
          <thead>
            <tr>
              <th>Key</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th>Deactivated</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map((item) => {
              return renderRow(item);
            })}
          </tbody>
        </Table>
      </ScrollArea>
    </Container>
  );
}
