import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <Auth0Provider
        domain="dev-sve71uk1.us.auth0.com"
        clientId="7SGAEqEi5g0b7Y2JHNIkEXFAiKHGbBfd"
        redirectUri={`${window.location.origin}/dashboard`}
        audience={process.env.REACT_APP_BACKEND_URL}
        scope="read:current_user update:current_user_metadata"
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
