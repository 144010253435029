import { Notification } from '@astroapi/types';
import { get_wrapper } from './BackendWrapper';
import { useQuery } from '@tanstack/react-query';

interface response_wrapper {
  data: any;
  message: string;
}

export const notifications_get = async (
  token: string
): Promise<Notification[]> => {
  const response: response_wrapper = await get_wrapper({
    path: '/notifications',
    token
  });
  const data: Notification[] = response.data.map((item: Notification) =>
    dateFixer(item)
  );
  return data;
};

// export const notifications_get_active = async (
//   token: string
// ): Promise<Notification[]> => {
//   const response: response_wrapper = await get_wrapper({
//     path: '/notifications/active',
//     token
//   });
//   const data: Notification[] = response.data.map((item: Notification) =>
//     dateFixer(item)
//   );
//   return data;
// };

const dateFixer = (notification: Notification) => {
  if (notification.createdAt)
    notification.createdAt = new Date(notification.createdAt);
  if (notification.updatedAt)
    notification.updatedAt = new Date(notification.updatedAt);
  if (notification.dismissedAt)
    notification.dismissedAt = new Date(notification.dismissedAt);
  return notification;
};

export const ActiveNotificationsQuery = (token?: string) => {
  return useQuery({
    queryKey: ['notifications', token],
    queryFn: () => {
      return notifications_get(token || '');
    }
  });
};
