import { Container, Title, SimpleGrid, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { DashNotif } from '../Components/Notification';
import { useUser } from '../Contexts/UserContext';
import { ActiveNotificationsQuery } from '../Utils/Backend/NotificationHelper';
import { ActiveSubscriptionsQuery } from '../Utils/Backend/SubscriptionHelper';
import { Notification } from '@astroapi/types';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '1vh 10vw'
  },

  item: {
    display: 'flex'
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
      .color,
    letterSpacing: 0.5
  },

  title: {
    lineHeight: 1,
    textAlign: 'center'
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs
  },

  highlight: {
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor
    }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit'
  }
}));

export default function NotificationsPage() {
  const [activeNotifications, setActiveNotifications] = useState<
    Notification[]
  >([]);
  const { accessToken } = useUser();
  const { classes } = useStyles();

  const { data, isLoading } = ActiveNotificationsQuery(accessToken);

  useEffect(() => {
    if (!accessToken || !data || !data.length) return;
    console.log(data);
    setActiveNotifications(data);
  }, [accessToken]);

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>Notifications</Title>

      <SimpleGrid
        cols={1}
        spacing={50}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 60, marginBottom: 40 }}
      >
        {activeNotifications.map((notif) => {
          return <DashNotif {...notif} />;
        })}
      </SimpleGrid>
    </Container>
  );
}
